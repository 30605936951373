/* global */

/* Fonts */
/* HomePage Title */
/* @import url("https://fonts.googleapis.com/css2?family=Baskervville&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap'); */

/* First Subtitle */
/* @import url('https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap'); */

/* Content main font */
/* @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap'); */

:root {
	--green-0: #9ff3b7;
	--green-25: #b2ff59;
	--green-50: #13ea4b;
	--green-75: #46b35f; /* #48B461*/
	--green-100: #228000; /* green from bootstrap */

	/* somehow cannot use var(--g) in rgba for hexcard, need to hardcode */
	--green-25-a75: rgba(178, 255, 89, 0.75);
	--green-50-a75: rgba(19, 234, 75, 0.75);
	--green-75-a75: rgba(70, 179, 95, 0.75);

	--gray-0: #f5f7fa;
	--gray-25: #bababa; /* b1b1b1 , c8c8c8 */
	--gray-50: #909090;
	--gray-75: #737373;
	--gray-100: #282c34;

	--gray-25-a75: rgba(186, 186, 186, 0.75);
	--gray-50-a75: rgba(144, 144, 144, 0.75);
	--gray-75-a75: rgba(115, 115, 115, 0.75);

	--blue-0: #d5e3ff;
	--blue-25: #ad9cfe;
	--blue-50: #6778b1;
	--blue-75: #5a38fd;
	--blue-100: #003;

	--blue-25-a75: rgba(173, 156, 254, 0.75);
	--blue-50-a75: rgba(103, 120, 177, 0.75);
	--blue-75-a75: rgba(90, 56, 253, 0.75);

	--grayblue-25: #9593a2;
	--grayblue-50: #6a687b;
}

/* universal elements */

/* h3,
h4,
h5,
h6,
span,
p,
small,
strong {
  font-family: "Exo 2", sans-serif;
} */

/* Links style */
.dot-link,
.icon-link {
	cursor: pointer;
	color: var(--green-75);
	font-weight: bold;
	text-decoration: underline var(--green-75);
	text-underline-offset: 0.5rem;
}
.dot-link {
	text-decoration-style: dotted;
}
.icon-link:hover,
.dot-link:hover,
.icon-link:active,
.dot-link:active {
	color: var(--green-100);
	text-decoration-style: solid;
}

/* Accessbility */
::-moz-selection {
	background: var(--green-0);
	/* Code for Firefox */
	color: var(--blue-50);
}

::selection {
	background: var(--green-0);
	color: var(--blue-50);
}

/* Privacy Preferences Button */
#termsfeed_pc1_sticky_element_open {
	font-size: 0.6rem;
	font-weight: bold;
	padding: 10px;
}

/*
 * From version 3, vertical-align: middle is not automatically given.
 * https://github.com/react-icons/react-icons
 * */
.react-icons {
	vertical-align: middle;
}
